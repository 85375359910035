<template>
    <main id="main" class="site-main site-main-detail" >
    <div class="postercard">
        <div class="postercard__img">
            <img :src="'http://127.0.0.1:8000/uploads/media/page/'">
        </div>
    </div>
    <section class="blog">
        <div class="container">
            <div class="row">
                <div class="col xs-12 sm-8 md-9">
                    <div class="breadcrumb">
                        <ul>
                            <li><router-link :to="{name:'Home'}">Home</router-link></li>
                            <li><span class="live">Enquire Confirm</span></li>
                        </ul>
                    </div>
                    <h1 class="folio__title">Thank You {{ $route.params.fullname }} for your query</h1>
                    <div class="reviser">
                        <p>Our representative will get in touch to you as soon as possible.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut urna at tellus tempor lacinia. Nunc egestas nec enim at tempus. Integer id tristique lectus. Fusce porta lectus lorem, vitae consequat felis interdum eu. In quis tortor ut felis iaculis euismod ut vel nisi. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam finibus tristique vehicula. Donec tincidunt ligula nec diam dapibus, ac tincidunt risus feugiat. Etiam gravida augue a tempus tempor. Donec turpis purus, ornare at augue ac, ullamcorper interdum augue. Praesent sit amet quam elit.</p>
                        <p>Aenean blandit fringilla nisi, eget hendrerit nisl condimentum eget. Integer sit amet est quis nisi pellentesque elementum consectetur eu magna. Curabitur rhoncus ante non dui venenatis, vel feugiat eros ultrices. Pellentesque congue dolor feugiat pellentesque iaculis. Fusce varius volutpat nisl facilisis imperdiet. Mauris eget urna ac est vestibulum pellentesque. Aenean lacinia magna quis augue fringilla, at lacinia quam porta. Fusce eu dui a orci dapibus gravida. Maecenas commodo metus vitae nulla venenatis euismod. Sed cursus vestibulum nunc, eleifend vestibulum sem condimentum ut. Sed lorem risus, scelerisque lacinia diam pellentesque, porta dignissim erat.</p>
                    </div>
                </div>
                <div class="col xs-12 sm-4 md-3">
                    
                    
                </div>
            </div>
        </div>
    </section>
    </main>
</template>